import React from "react";
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import hiral from "../../assets/images/profile/hiral.png";
const data = {
  name: "Hiral Shah",
  description:
    "A graduate in Commerce, Hiral found her calling in the teaching field and completed a Diploma in Nursery Teachers’ Training. She has been working as a kindergarten teacher since 6 years. She also conducts Jolly Phonics training for children. Being a passionate educator and having keen interest in art and craft, Hiral enthusiastically adopts innovative ways to engage with young learners through activity-based training methods. ",
};

const TeachersDetailsPage = () => {
  return (
    <Layout pageTitle="KWEC | Teachers Details">
      <Navbar />
      <PageHeader title="Our Team" />
      <section className="team-details">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4">
              <div className="team-one__single">
                <div className="team-one__image">
                  <img src={hiral} alt="" />
                </div>
                <div className="team-one__content">
                  <h2 className="team-one__name">
                    {data.name}
                  </h2>
                  <p className="team-one__designation">Early Years Educator</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="team-details__content">
                <h2 className="team-details__title">Read my story</h2>
                <p className="team-details__text">{data.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default TeachersDetailsPage;
